<template>
    <div class="head-secondary">
        <span>The Columbia Hike Society guarantees unforgettable moments in nature, where sharing and discovery are at the heart of every experience.</span>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

<style lang="scss">
.head-secondary {
    background: #909B3F;
    color: $white;
    width: 100%;
    padding: 8px 24px;
    font-size: 0.8em;
}
@media(min-width: 672px){
    .head-secondary {
        padding-inline: 7% !important;
    }
}
</style>